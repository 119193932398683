import type { VideoPlayerData } from '~~/types'
import { ref, defineStore } from '#imports'

export const useVideoPlayer = defineStore('videoPlayer', () => {
  const selectedVideo = ref<VideoPlayerData>()

  return {
    // state
    selectedVideo,
  }
})
